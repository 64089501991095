<template lang="pug">
	.widget-container
		el-row(:gutter="24")
			el-col(:xs="24")
				el-card.box-card.text-left
					.clearfix.d-flex.j-between(slot="header")
						h2.no-line-height.text-left Settings
						.extra
					h3 Description
					p Use the form below to change or set questions and answers of the bot.&nbsp;
						| The changes will take place after you click on "Save" button.
					br
					h3 Form
					el-form(ref="form" :model="form" :rules="rules" layout="vertical")
						el-row(:gutter="24")
							el-col(:span="24")
								el-tooltip(content="Not implemented yet")
									el-form-item(label="Welcome Question" has-feedback="" ref="itemWELCOME_MSG" prop="WELCOME_MSG")
										el-input(type="textarea" disabled="" v-model="form.WELCOME_MSG")

							el-col(:xs="24" :sm="12")
								h3 Validation Errors
								el-row(:gutter="24")
									el-col(:span="24")
										el-form-item(label="Wrong Format - Email" has-feedback="" ref="itemVALIDATE_EMAIL" prop="VALIDATE_EMAIL")
											el-input(v-model="form.VALIDATE_EMAIL")
									el-col(:span="24")
										el-form-item(label="Wrong Format - Phone number" has-feedback="" ref="itemVALIDATE_PHONE" prop="VALIDATE_PHONE")
											el-input(v-model="form.VALIDATE_PHONE")
									el-col(:span="24")
										el-form-item(label="Already Subscribed" has-feedback="" ref="itemDUPLICATE" prop="DUPLICATE")
											el-input(v-model="form.DUPLICATE")
									el-col(:span="24")
										el-form-item(label="Wrong Command" has-feedback="" ref="itemWRONG_CMD" prop="WRONG_CMD")
											el-input(v-model="form.WRONG_CMD")
							el-col(:xs="24" :sm="12")
								h3 Questions
								el-row(:gutter="24")
									el-col(:span="24")
										el-form-item(label="What is your email?" has-feedback="" ref="itemQUESTION_1" prop="QUESTION_1")
											el-input(v-model="form.QUESTION_1")
										el-form-item(label="What is your phone number?" has-feedback="" ref="itemQUESTION_2" prop="QUESTION_2")
											el-input(v-model="form.QUESTION_2")
							el-col(:span="24")
								el-row
									h3 Success / Fail text
							el-col(:span="24")
								el-form-item(label="Something went wrong" has-feedback="" ref="itemFAILED_RESPONSE" prop="FAILED_RESPONSE")
									el-input(type="textarea" v-model="form.FAILED_RESPONSE")
							el-col(:span="24")
								el-form-item(label="Successful Sign Up" has-feedback="" ref="itemSUCCESS_RESPONSE" prop="SUCCESS_RESPONSE")
									el-input(type="textarea" :rows="4" v-model="form.SUCCESS_RESPONSE")
						el-row
							el-col(:span="24")
								el-row
									h3 Acknowledgement
							el-col(:xs="24" :sm="12" :lg="8")
								el-form-item(label="Channel to acknowledge of expirations" ref="itemNOTIFY_EXPIRATIONS" prop="NOTIFY_EXPIRATIONS"  has-feedback="")
									el-input(placeholder="E.g. -1001511111111" v-model="form.NOTIFY_EXPIRATIONS")
					el-divider
					el-row(:gutter="12")
						el-col(:span="24")
							el-button(type="primary" size="midi" @click="submit") Save
							el-button(type="danger" size="midi" @click="reset") Reset Form
</template>

<style lang="scss">

</style>

<script>
import {GetSettings, UpdateSettingsList} from "@/api/settings";
import {
	validateTelegramChannel,
	validateTelegramText,
	validateTelegramTextLong,
	validateTelegramTextShort
} from "@/utils/validate";

const $form = {
	WELCOME_MSG: '',
	QUESTION_1: '',
	QUESTION_2: '',
	VALIDATE_EMAIL: '',
	VALIDATE_PHONE: '',
	DUPLICATE: '',
	WRONG_CMD: '',
	FAILED_RESPONSE: '',
	SUCCESS_RESPONSE: '',
	NOTIFY_EXPIRATIONS: ''
}

export default {
	data() {
		return {
			form: {
				WELCOME_MSG: 'Напишите /start Чтобы получить доступ к сигналам CMSignals на Ваш телефон.',
				QUESTION_1: '',
				QUESTION_2: '',
				VALIDATE_EMAIL: '',
				VALIDATE_PHONE: '',
				DUPLICATE: '',
				WRONG_CMD: '',
				FAILED_RESPONSE: '',
				SUCCESS_RESPONSE: '',
				NOTIFY_EXPIRATIONS: ''
			},
			rules: {
				// WELCOME_MSG: { required: true, validator: validateTelegramTextLong, trigger: 'blur' },
				QUESTION_1: { required: true, validator: validateTelegramTextShort, trigger: 'blur' },
				QUESTION_2: { required: true, validator: validateTelegramTextShort, trigger: 'blur' },
				VALIDATE_EMAIL: { required: true, validator: validateTelegramTextShort, trigger: 'blur' },
				VALIDATE_PHONE: { required: true, validator: validateTelegramTextShort, trigger: 'blur' },
				DUPLICATE: { required: true, validator: validateTelegramText, trigger: 'blur' },
				WRONG_CMD: { required: true, validator: validateTelegramText, trigger: 'blur' },
				FAILED_RESPONSE: { required: true, validator: validateTelegramTextLong, trigger: 'blur' },
				SUCCESS_RESPONSE: { required: true, validator: validateTelegramTextLong, trigger: 'blur' },
				NOTIFY_EXPIRATIONS: { required: false, validator: validateTelegramChannel, trigger: 'blur' },
			},
		}
	},
	async beforeRouteEnter(to, from, next) {
		if(to.path!=='/settings/list') return next('/');
		try {
			const result = await GetSettings();
			next(vm => {
				vm.form = {...result}
			});
		} catch (e) {
			console.error(e);
			next('/')
		}
	},
	methods: {
		submit() {
			this.$refs.form.validate(async valid => {
				if(!valid) return this.$message.error('Validation failed');
				UpdateSettingsList(this.form)
						.then(res => {
							this.$notify.success({title: 'API Response', message: 'Texts were successfully updated!'})
						})
			})
		},
		reset() {
			console.log($form);
			this.form = Object.assign({}, $form);

		}
	}
}
</script>
